<template>
  <div class="wnl_page">
    <v-header :params="{ title, leftIcon: true }"></v-header>
    <div class="wnl_header">
      <div class="monthOrYear">
        <h1>{{this.nowMonth.split("-")[0] + "年" + this.nowMonth.split("-")[1] + "月"}}</h1>
        <div style="width: 40%;height: 100%;display: inline-block;float: right">
          <div style="height: 50%;width: 100%">
            <van-icon size="30px" name="notes-o" style="float: right;margin-top: 5px;margin-right: 15px;margin-bottom: 5px" @click="openDialog"></van-icon>
          </div>
          <div style="height: 50%;width: 100%">
            <h5 style="text-align: right">{{this.nonliNianYue[0]}}年{{this.nonliNianYue[1]}}</h5>
          </div>
        </div>
      </div>
      <div class="dayMes">
        <div class="weekDay">
          <div v-for="(item,index) in weekMesList" :key="index" class="weekDayNum">
            <a v-if="item === '日' || item === '六'" style="color: #e0675e;font-size: 18px">{{item}}</a>
            <a v-if="item !== '日' && item !== '六'" style="font-size: 18px">{{item}}</a>
          </div>
        </div>
        <div v-for="(item,index) in dateMesList" :key="index + item.keyNum" :class="item.dayFormat === nowDay ? 'dayItemCheck' : 'dayItem'" ref="check" @click="checkDay(index)">
          <a :class="item.itemClassA">{{item.dayNum}}</a>
          <p :class="item.itemClassP">{{item.dayCode}}</p>
        </div>
      </div>
      <div class="bottomButton">
        <button style="margin-right: 1px" @click="changeMonth('last')">上一月</button>
        <button style="margin-left: 1px" @click="changeMonth('next')">下一月</button>
      </div>
    </div>
    <van-dialog style="width: 95%" v-model="dialogShow" :beforeClose="onBeforeClose" :confirm-button-text="confirmBtnType === 1 ? '确认' : '保存'" :cancel-button-text="confirmType === 3 ? '返回' : '取消'" :showConfirmButton="confirmType !== 3" :showCancelButton="true" class="dialogClass" @confirm="saveBtn" @cancel="cancelBtn">
      <div class="noteAndDateBox">
        <div class="dateBox">
          <div>
            <div>
              <van-icon name="arrow-left" size="20px" style="float: right;margin: auto" @click="dialogLastDay" v-if="iconShow"></van-icon>
            </div>
          </div>
          <div>
            <div>
              <span>{{this.dateBoxStr}}</span>
            </div>
          </div>
          <div>
            <div>
              <van-icon name="arrow" size="20px" style="float: left;margin: auto" @click="dialogNextDay" v-if="iconShow"></van-icon>
            </div>
          </div>
        </div>
        <div class="textBox" v-if="this.showTextBox">
          <van-collapse v-model="activeNames">
            <van-collapse-item title="文本" name="1" class="textClass">
              <div v-for="(item,index) in noteList" :key="index">
                <span> {{item.title}}</span>
                <button style="margin-left: 3px" @click="deletedNote(index)" class="deletedBtn">删除</button>
                <button @click="showNoteBoxBtn(index,1)" class="editBtn">编辑</button>
                <span style="float: right;margin-right: 3px">{{item.startTime}}</span>
              </div>
              <div>
                <span class="addText">  </span>
                <button class="addBtn" @click="addNoteBoxBtn">+添加</button>
              </div>
            </van-collapse-item>
            <van-collapse-item title="代办" name="2" class="AgentClass">
              <div class="AgentItemClass">
                <van-collapse-item :title="ite.title" v-for="(ite,index) in this.agentList" :key="index" :name="3+index" class="AgentChildrenClass">
                  <div class="AgentChildrenMes" v-for="(matter,index1) in ite.contents" :key="index1">
                    <div>
                      <span :class="matter.finishStatus === 1 ? 'completedSpan' : 'noCompletedSpan'">{{matter.title}}</span>
                    </div>
                    <div>
                      <button style="margin-left: 3px" class="deletedBtn" @click="agentMatterDeleted(index,index1)">删除</button>
                      <button class="editBtn" @click="showNoteBoxBtn(index,2,index1)">编辑</button>
                      <div :class="matter.finishStatus === 1 ? 'completedIconSuccess' : 'noCompletedIcon'" style="margin: 0px 1px">
                        <van-icon name="success" style="margin: auto 3px auto 3px;float: right" size="20px" @click="completedBtn(index,index1)"></van-icon>
                      </div>
                      <div :class="matter.finishStatus === 0 ? 'completedIconCross' : 'noCompletedIcon'" style="margin: 0px 1px">
                        <van-icon name="cross" style="margin: auto 3px auto 3px;float: right" size="20px" @click="noCompletedBtn(index,index1)"></van-icon>
                      </div>
                    </div>
                  </div>
                  <div>
                    <span class="addText">  </span>
                    <button class="addBtn" @click="addAgentMatterBtn(index)">+添加事项</button>
                  </div>
                </van-collapse-item>
                <div>
                  <span class="addText">  </span>
                  <button class="addBtn" @click="agentManageBtn">+主题管理</button>
                </div>
              </div>
            </van-collapse-item>
          </van-collapse>
        </div>
        <div class="noteBox" v-if="this.showNoteBox">
          <div class="timeBox">
            <span>创建时间:{{this.editObj.startTime}}</span>
            <span>最后修改时间:{{this.editObj.endEditTime}}</span>
          </div>
          <van-field
              v-model="titleText"
              rows="1"
              autosize
              placeholder="请输入标题"
              class="titleBoxClass"
              maxlength="8"
          />
          <van-field
              v-model="noteText"
              placeholder="请输入笔记"
              type="textarea"
              autosize
              class="noteBoxClass"
          />
        </div>
        <div class="AgentManage" v-if="this.AgentManage">
          <div v-for="(item,index) in this.agentList" :key="index" class="agentItemClass">
            <div>
              <span v-if="item.inputShow">
                {{item.title}}
              </span>
              <input placeholder="请输入主题" v-if="!item.inputShow" v-model="item.title">
            </div>
            <div>
              <button style="margin-left: 3px" class="deletedBtn" v-if="item.operateShow" @click="agentOperate(1,index)">删除</button>
              <button class="editBtn" v-if="item.operateShow" @click="agentOperate(2,index)">编辑</button>
              <button style="margin-left: 3px" class="cancelBtn" v-if="!item.operateShow" @click="agentOperate(3,index)">取消</button>
              <button class="saveBtn" v-if="!item.operateShow" @click="agentOperate(4,index)">保存</button>
            </div>
          </div>
          <div class="addAgentClass">
            <span class="addText">  </span>
            <button class="addBtn" @click="addAgent">+添加主题</button>
          </div>
        </div>
      </div>
    </van-dialog>
    <van-dialog class="deletedDialog" show-confirm-button show-cancel-button></van-dialog>
  </div>
</template>
<script>
import vHeader from "@/components/header.vue";
import isWeixin from "@/utils/utils.js";
export default {
  components: {
    vHeader,
  },
  mounted:function(){
    //判断网页是什么打开
    let isWeiXin = isWeixin()
    if(isWeiXin){
      this.showHeader = false
    }
    if(this.showHeader==false){
      document.title = ''
    }
    this.getNowDate();
    this.dateMesProduct();
    this.productDatePar();
  },
  data(){
    return{
      title: this.$route.meta.title,
      weekMesList: ["日","一","二","三","四","五","六"],
      dateMesList:[],
      nowDay: "",
      nowMonth: "",
      nonliNianYue: [],
      dialogShow: false,
      dateIndex: 0,
      dateBoxStr: "",
      activeNames: ['1'],
      titleText: '',
      noteText: '',
      showTextBox: false,
      showNoteBox: false,
      confirmBtnType: 1, //确认按钮类型 1类型选择 2文本保存
      iconShow: true,
      wnlObj: {}, //当前万年历对象
      noteList: [], //笔记列表
      agentList: [], //代办列表
      confirmType: 0,//提交类型 1添加 2修改
      editObj: {}, //当前编辑对象
      AgentManage: false, //主题管理面板
      agentEditObj:{}, //主题编辑对象
      agentUpdateObj: {}, //主题更新对象 用于更新明细json数据
      agentMatterIndex: -1 //明细json对象下标
    }
  },
  methods:{
    completedBtn(index,index2){
      this.agentList[index].contents[index2].finishStatus = 1;
      this.agentUpdateObj = this.agentList[index];
      this.agentMatterIndex = index2;
      this.confirmType = 4;
      this.confirmBtnType = 2;
      this.titleText = this.agentList[index].contents[index2].title;
      this.noteText = this.agentList[index].contents[index2].contents;
      this.saveBtn();
      this.onloadNoteList();
    },
    noCompletedBtn(index,index2){
      this.agentList[index].contents[index2].finishStatus = 0;
      this.agentUpdateObj = this.agentList[index];
      this.agentMatterIndex = index2;
      this.confirmType = 4;
      this.confirmBtnType = 2;
      this.titleText = this.agentList[index].contents[index2].title;
      this.noteText = this.agentList[index].contents[index2].contents;
      this.saveBtn();
      this.onloadNoteList();
    },
    onBeforeClose(action, done) {
      return done(false)
    },
    addAgent(){
      let NoteDTO = {
        noteId: '',
        wnlId: '',
        noteKind: 2,
        title: '',
        contents: '',
        userId: '',
        systemId: '',
        endEditTime: '',
        startTime: '',
        inputShow: false,
        operateShow: false
      }
      this.agentList.push(NoteDTO);
    },
    agentOperate(operateType,itemIndex){
      switch (operateType) {
        case 1:
          this.deletedNote(itemIndex);
          break;
        case 2:
          this.agentList[itemIndex].inputShow = false;
          this.agentList[itemIndex].operateShow = false;
          break;
        case 3:
          if (this.agentList[itemIndex].noteId === '' || this.agentList[itemIndex].noteId === null){
            this.agentList.splice(itemIndex,1);
          } else {
            this.agentList[itemIndex].inputShow = true;
            this.agentList[itemIndex].operateShow = true;
          }
          break;
        case 4:
          this.agentEditObj = this.agentList[itemIndex];
          this.saveBtn();
          break;
      }
      this.$forceUpdate();
    },
    async deletedEvent(index){
      let deletedObj = {
        noteId: null
      }
      if (this.confirmType === 3){
        deletedObj.noteId = this.agentList[index].noteId;
      } else {
        deletedObj.noteId = this.noteList[index].noteId;
      }
      const res = await this.$HTTP.post(this, 'API_NOTE_DELETED_ONE', deletedObj, { headers: { access_token: this.$store.state.common.loginInfo.access_token } }, true);
      if (res.statusCode === 200){
        this.$toast('删除成功');
      }
      this.onloadNoteList();
    },
    async deletedNote(index){
      let message = '';
      if (this.confirmType === 3){
        message = '是否删除该主题';
      } else {
        message = '请确认是否删除该笔记';
      }
      this.$dialog.confirm({
        message:message,
        confirmButtonColor:'red'
      }).then(()=>{
        this.deletedEvent(index);
      }).catch(()=>{
        console.log('点击了取消')
      })
    },
    async agentMatterDeleted(index,index2){
      this.agentUpdateObj = this.agentList[index];
      this.$dialog.confirm({
        message:'是否删除该代办',
        confirmButtonColor:'red'
      }).then(()=>{
        this.agentMatterDeletedEvent(index2);
      }).catch(()=>{
        console.log('点击了取消')
      })
    },
    async agentMatterDeletedEvent(index){
      this.agentUpdateObj.contents.splice(index,1);
      this.agentUpdateObj.contents = JSON.stringify(this.agentUpdateObj.contents);
      const res = await this.$HTTP.post(this, 'API_NOTE_UPDATE_ONE', this.agentUpdateObj, { headers: { access_token: this.$store.state.common.loginInfo.access_token } }, true);
      if (res.statusCode === 200){
        this.$toast('保存成功');
      }
      this.agentUpdateObj = {};
      this.agentMatterIndex = -1;
      this.onloadNoteList();
    },
    cancelBtn(){
      if (this.confirmType === 3){
        this.agentList = this.agentList.filter((item) => item.noteId !== '');
      }
      if (this.confirmType === 4){
        this.agentUpdateObj = {};
        this.agentMatterIndex = -1;
      }
      this.confirmType = 0;
      if (this.confirmBtnType === 1){
        this.dialogShow = false;
      } else if (this.confirmBtnType === 2){
        this.dialogShow = true;
        this.confirmBtnType = 1;
        this.showNoteBox = false;
        this.AgentManage = false;
        this.showTextBox = true;
        this.iconShow = true;
        this.noteText = '';
        this.titleText = '';
      }
    },
    async saveBtn(){
      if (this.confirmBtnType === 1){
        this.dialogShow = false;
      } else if (this.confirmBtnType === 2){
        if (this.confirmType === 1){
          if (this.titleText === ''){
            this.$toast('标题不能为空');
            return;
          }
          let pushObj = {
            wnlId: this.wnlObj.wnlId,
            noteKind: 1,
            title: this.titleText,
            contents: this.noteText,
          }
          const res = await this.$HTTP.post(this, 'API_NOTE_ADD_ONE', pushObj, { headers: { access_token: this.$store.state.common.loginInfo.access_token } }, true);
          if (res.statusCode === 200){
            this.$toast('发表成功');
          }
        } else if (this.confirmType === 2){
          let updateObj = {
            noteId: this.editObj.noteId,
            noteKind: 1,
            title: this.titleText,
            contents: this.noteText,
          }
          const res = await this.$HTTP.post(this, 'API_NOTE_UPDATE_ONE', updateObj, { headers: { access_token: this.$store.state.common.loginInfo.access_token } }, true);
          if (res.statusCode === 200){
            this.$toast('保存成功');
          }
        } else if (this.confirmType === 3){
          if (this.agentEditObj.noteId === ''){
            if (this.agentEditObj.title === ''){
              this.$toast('主题不能为空');
              return;
            }
            let pushObj = {
              wnlId: this.wnlObj.wnlId,
              noteKind: 2,
              title: this.agentEditObj.title,
            }
            const res = await this.$HTTP.post(this, 'API_NOTE_ADD_ONE', pushObj, { headers: { access_token: this.$store.state.common.loginInfo.access_token } }, true);
            if (res.statusCode === 200){
              this.$toast('保存成功');
            }
          } else {
            let updateObj = {
              noteId: this.agentEditObj.noteId,
              title: this.agentEditObj.title,
            }
            const res = await this.$HTTP.post(this, 'API_NOTE_UPDATE_ONE', updateObj, { headers: { access_token: this.$store.state.common.loginInfo.access_token } }, true);
            if (res.statusCode === 200){
              this.$toast('保存成功');
            }
          }
          this.onloadNoteList();
          return;
        } else if (this.confirmType === 4){
          if (this.agentMatterIndex === -1){
            if (this.titleText === ''){
              this.$toast('标题不能为空');
              return;
            }
            let matterObj = {
              title: this.titleText,
              contents: this.noteText,
              finishStatus: 0,
            }
            if (this.agentUpdateObj.contents === null){
              this.agentUpdateObj.contents = [];
              this.agentUpdateObj.contents.push(matterObj);
            } else {
              this.agentUpdateObj.contents.push(matterObj);
            }
            this.agentUpdateObj.contents = JSON.stringify(this.agentUpdateObj.contents);
            const res = await this.$HTTP.post(this, 'API_NOTE_UPDATE_ONE', this.agentUpdateObj, { headers: { access_token: this.$store.state.common.loginInfo.access_token } }, true);
            if (res.statusCode === 200){
              this.$toast('保存成功');
            }
            this.agentUpdateObj = {};
            this.agentMatterIndex = -1;
          } else {
            this.agentUpdateObj.contents[this.agentMatterIndex].title = this.titleText;
            this.agentUpdateObj.contents[this.agentMatterIndex].contents = this.noteText;
            this.agentUpdateObj.contents = JSON.stringify(this.agentUpdateObj.contents);
            const res = await this.$HTTP.post(this, 'API_NOTE_UPDATE_ONE', this.agentUpdateObj, { headers: { access_token: this.$store.state.common.loginInfo.access_token } }, true);
            if (res.statusCode === 200){
              this.$toast('保存成功');
            }
            this.agentUpdateObj = {};
            this.agentMatterIndex = -1;
          }

        }
        this.onloadNoteList();
        this.dialogShow = true;
        this.confirmBtnType = 1;
        this.showNoteBox = false;
        this.showTextBox = true;
        this.noteText = '';
        this.titleText = '';
        this.iconShow = true;
      }
    },
    agentManageBtn(){
      this.confirmType = 3;
      this.confirmBtnType = 2;
      this.showTextBox = false;
      this.AgentManage = true;
      this.iconShow = false;
    },
    async onloadNoteList(){
      let onloadObj = {
        wnlId: this.wnlObj.wnlId
      }
      const res = await this.$HTTP.post(this, 'API_NOTE_GET_ONE', onloadObj, { headers: { access_token: this.$store.state.common.loginInfo.access_token } }, true);
      this.noteList = res.data.filter((item) => item.noteKind === 1);
      this.agentList = res.data.filter((item) => item.noteKind === 2);
      this.agentList = this.agentList.map((item) =>{
        let newItem = item;
        newItem.contents = JSON.parse(item.contents);
        return newItem;
      });
      this.agentList.forEach((item) =>{
        item["inputShow"] = true;
        item["operateShow"] = true;
      })
    },
    showNoteBoxBtn(index,btnType,index2){
      if (btnType === 1){
        this.titleText = this.noteList[index].title;
        this.noteText = this.noteList[index].contents;
        this.editObj = this.noteList[index];
        this.confirmType = 2;
      } else if (btnType === 2){
        this.agentUpdateObj = this.agentList[index];
        this.agentMatterIndex = index2;
        this.titleText = this.agentList[index].contents[index2].title;
        this.noteText = this.agentList[index].contents[index2].contents;
        this.confirmType = 4;
      }
      this.confirmBtnType = 2;
      this.showTextBox = false;
      this.showNoteBox = true;
      this.iconShow = false;
    },
    addNoteBoxBtn(){
      this.confirmType = 1;
      this.confirmBtnType = 2;
      this.showTextBox = false;
      this.showNoteBox = true;
      this.iconShow = false;
    },
    addAgentMatterBtn(index){
      this.agentUpdateObj = this.agentList[index];
      this.confirmType = 4;
      this.confirmBtnType = 2;
      this.showTextBox = false;
      this.showNoteBox = true;
      this.iconShow = false;
    },
    async openDialog(){
      if (JSON.stringify(this.$store.state.common.loginInfo) === '{}'){
        this.$toast('请先登录');
        this.$router.push({name:'login',params:{return_url:'attendance'}});
        return;
      }
      if (this.$store.state.common.loginInfo.access_token === null || this.$store.state.common.loginInfo.access_token === ''){
        this.$toast('请先登录');
        this.$router.push({name:'login',params:{return_url:'attendance'}});
        return;
      }
      if (this.$store.state.common.loginInfo.userId === null || this.$store.state.common.loginInfo.userId === ''){
        this.$toast('请先登录');
        this.$router.push({name:'login',params:{return_url:'attendance'}});
        return;
      }
      this.wnlObj = this.dateMesList[this.dateIndex];
      this.onloadNoteList();
      this.dialogShow = true;
      this.dateBoxStr = this.dateMesList[this.dateIndex].dayFormat;
      this.showTextBox = true;
    },
    dialogLastDay(){
      this.dateIndex = this.dateIndex - 1;
      this.dateBoxStr = this.dateMesList[this.dateIndex].dayFormat;
      this.checkDay(this.dateIndex);
      this.wnlObj = this.dateMesList[this.dateIndex];
      this.onloadNoteList();
    },
    dialogNextDay(){
      this.dateIndex = this.dateIndex + 1;
      this.dateBoxStr = this.dateMesList[this.dateIndex].dayFormat;
      this.checkDay(this.dateIndex);
      this.wnlObj = this.dateMesList[this.dateIndex];
      this.onloadNoteList();
    },
    async dateMesProduct(monthCond){
      let nowMonth = null;
      if (monthCond !== undefined){
        nowMonth = this.productDatePar(monthCond);
      } else {
        nowMonth = this.productDatePar();
      }
      this.dateMesList = [];
      this.nonliNianYue = [];
      const res = await this.$HTTP.post(this,'API_WNL_ONE',{requestDate:nowMonth.startDatePar,requestEndDate:nowMonth.endDatePar},{},true);
      let dateMidDay = new Date(res.data[15].wnlDate);
      let midMonthNow = dateMidDay.getMonth() + 1;
      let midYearNow = dateMidDay.getFullYear();
      if (midMonthNow >= 1 && midMonthNow <= 9) {
        midMonthNow = "0" + midMonthNow;
      }
      let nongLiYueIndex = 60;
      for (let i=0;i<res.data.length;i++){
        let date = new Date(res.data[i].wnlDate);
        let monthNow = date.getMonth() + 1;
        let yearNow = date.getFullYear();
        let day = date.getDate();
        if (monthNow >= 1 && monthNow <= 9) {
          monthNow = "0" + monthNow;
        }
        let nowDateStr = yearNow + "-" + monthNow + "-" + day;
        let itemClassA = null;
        let itemClassP = null;
        if (monthNow === midMonthNow){
          if (['星期六','星期日'].includes(res.data[i].wnlWeek.trim())){
            itemClassA = 'itemLabelAWeekend';
          } else {
            itemClassA = 'itemLabelA';
          }
          itemClassP = 'itemLabelP';
        } else {
          itemClassA = 'itemLabelALastMonth';
          itemClassP = 'itemLabelPLastMonth';
        }
        let dayCode1 = null;
        if (res.data[i].gonglijieri.trim() !== ""){
          dayCode1 = res.data[i].gonglijieri;
        } else if (res.data[i].nonglijieri.trim() !== ""){
          dayCode1 = res.data[i].nonglijieri;
        } else if (res.data[i].teshujieri.trim() !== ""){
          dayCode1 = res.data[i].teshujieri;
        } else if (res.data[i].jieqi.trim() !== ""){
          dayCode1 = res.data[i].jieqi;
        } else {
          dayCode1 = res.data[i].nongliri;
        }
        let dateObj = {
          wnlId: res.data[i].id,
          dayNum: day.toString(),
          dayFormat: nowDateStr.toString(),
          dayCode: dayCode1,
          dayWeek: res.data[i].wnlWeek.trim(),
          keyNum: i.toString(),
          shuxiang: res.data[i].shuxiang.trim(),
          nongliyue: res.data[i].nongliyue.trim(),
          itemClassA: itemClassA,
          itemClassP: itemClassP
        }
        this.dateMesList.push(dateObj);
        if (this.nowDay === dateObj.dayFormat){
          nongLiYueIndex = i;
          this.dateIndex = i;
        }
      }
      if (nongLiYueIndex !== 60){
        let nongliNianYueItem = res.data[nongLiYueIndex];
        this.nonliNianYue.push(nongliNianYueItem.shuxiang.trim());
        this.nonliNianYue.push(nongliNianYueItem.nongliyue.trim());
      } else {
        let nongliNianYueItem = res.data[15];
        this.nonliNianYue.push(nongliNianYueItem.shuxiang.trim());
        this.nonliNianYue.push(nongliNianYueItem.nongliyue.trim());
      }
      this.nowMonth = midYearNow + "-" + midMonthNow;
    },
    getNowDate(){
      let nowDate = new Date;
      let monthNow = nowDate.getMonth() + 1;
      let yearNow = nowDate.getFullYear();
      let day = nowDate.getDate();
      if (monthNow >= 1 && monthNow <= 9) {
        monthNow = "0" + monthNow;
      }
      let nowDateStr = yearNow + "-" + monthNow + "-" + day;
      this.nowDay = nowDateStr;
    },
    changeMonth(cond){
      let nextMonth = this.nowMonth + "-01";
      let date = new Date(nextMonth);
      if (cond === 'next'){
        date.setMonth(date.getMonth() + 1);
      } else {
        date.setMonth(date.getMonth() - 1);
      }
      this.dateMesProduct(date);
    },
    productDatePar(monthCond){
      let date = null;
      if (monthCond !== undefined){
        date = monthCond;
      } else {
        date = new Date();
      }
      date.setDate(1);
      let startWeekDay = date.getDay();
      date.setDate(date.getDate() - startWeekDay);
      let startDate = date.getFullYear().toString() + "-" + (date.getMonth() + 1).toString() + "-" + date.getDate().toString();
      date.setDate(date.getDate() + startWeekDay);
      date.setMonth(date.getMonth() + 1);
      date.setDate(date.getDate() - 1);
      let endWeekDay = date.getDay();
      date.setDate(date.getDate() + (7 - (endWeekDay + 1)));
      let endDate = date.getFullYear().toString() + "-" + (date.getMonth() + 1).toString() + "-" + date.getDate().toString();
      let returnDate = {
        startDatePar: startDate,
        endDatePar: endDate
      }
      return returnDate;
    },
    checkDay(itemIndex){
      this.dateIndex = itemIndex;
      let check = this.$refs.check;
      let checkItem = null;
      for (let i=0;i<this.dateMesList.length;i++){
        if (check[i].className !== 'dayItemCheck'){
          check[i].style.backgroundColor = '';
        } else {
          checkItem = i;
        }
      }
      if (check[itemIndex].className !== 'dayItemCheck'){
        check[itemIndex].style.backgroundColor = 'yellow';
        if (checkItem !== null){
          check[checkItem].style.color = 'white';
        }
      } else {
        check[itemIndex].style.color = 'black';
      }
      let dateMesListElement = this.dateMesList[itemIndex];
      this.nonliNianYue = [];
      this.nonliNianYue.push(dateMesListElement.shuxiang);
      this.nonliNianYue.push(dateMesListElement.nongliyue);
    }
  },
}
</script>
<style lang="less">
.wnl_page{
  .wnl_header{
    width: 100%;
    height: 100%;

    .monthOrYear{
      width: 100%;
      height: 20%;
      background-color: #e0675e;
      color: white;

      h1{
        margin: 0;
        padding: 10px;
        display: inline-block;
        width: 50%;
        height: 80%;
        font-size: 30px;
      }

      h5{
        display: inline-block;
        float: right;
        height: 50%;
        width: 100%;
        padding: 0;
        margin: 0;
        font-size: 0.37333rem;
        margin-right: 10px;
        //margin-top: 0.8rem;
      }
    }

    .dayMes{
      width: 100%;
      height: 60%;
      display: inline;

      .weekDay{
        width: 100%;
        height: 9%;
        margin: 0;
        padding: 0;
        display: flex;

        .weekDayNum{
          width: 12%;
          height: 9%;
          display: inline-block;
          margin: 6px 4px;
          text-align: center;
          border-radius: 20%;
        }
      }

      .dayItem{
        width: 12%;
        height: 9%;
        display: inline-block;
        margin: 6px 4px;
        text-align: center;
        border-radius: 20%;

        .itemLabelAWeekend{
          font-size: 18px;
          display: block;
          margin-top: 5px;
          height: 60%;
          width: 100%;
          color: #e0675e;
        }

        .itemLabelA{
          font-size: 18px;
          display: block;
          margin-top: 5px;
          height: 60%;
          width: 100%;
        }

        .itemLabelALastMonth{
          font-size: 18px;
          display: block;
          margin-top: 5px;
          height: 60%;
          width: 100%;
          color: grey;
        }

        .itemLabelP{
          display: block;
          height: 40%;
          width: 100%;
          font-size: 10px;
          margin: 0;
          margin-bottom: 3px;
        }

        .itemLabelPLastMonth{
          display: block;
          height: 40%;
          width: 100%;
          font-size: 10px;
          margin: 0;
          margin-bottom: 3px;
          color: grey;
        }
      }

      .dayItemCheck{
        width: 12%;
        height: 9%;
        display: inline-block;
        margin: 6px 4px;
        text-align: center;
        background-color: #e0675e;
        color: white;
        border-radius: 20%;

        .itemLabelA{
          font-size: 18px;
          display: block;
          margin-top: 5px;
          height: 60%;
          width: 100%;
        }

        .itemLabelALastMonth{
          font-size: 18px;
          display: block;
          margin-top: 5px;
          height: 60%;
          width: 100%;
        }

        .itemLabelP{
          display: block;
          height: 40%;
          width: 100%;
          font-size: 10px;
          margin: 0;
          margin-bottom: 3px;
        }

        .itemLabelPLastMonth{
          display: block;
          height: 40%;
          width: 100%;
          font-size: 10px;
          margin: 0;
          margin-bottom: 3px;
        }
      }
    }

    .bottomButton{
      width: 100%;
      position: fixed;
      bottom: 0;
      color: white;

      button{
        width: 49.5%;
        height: 100%;
        border: none;
        padding: 0;
        margin: 0;
        background-color: #e0675e;

      }
    }


  }
  .dialogClass{
    .noteAndDateBox{
      width: 100%;

      .dateBox{
        width: 100%;
        height: 10%;
        margin-top: 10px;
        padding-bottom: 5px;
        border-bottom: solid;
        border-width: 0.5px;

        div:nth-child(1){
          width: 30%;
          height: 100%;
          display: inline-block;

          div{
            display: inline-flex;
            float: right;
          }
        }
        div:nth-child(2){
          width: 40%;
          height: 100%;
          display: inline-block;
          text-align: center;

          div{
            display: inline-flex;
            width: 100%;
            height: 100%;
            float: left;

            span{
              margin: auto;
            }
          }
        }
        div:nth-child(3){
          width: 30%;
          height: 100%;
          display: inline-block;

          div{
            display: inline-flex;
            float: left;
          }
        }
      }

      .textBox{
        height: calc(100vh - 200px);
        overflow: scroll;

        .textClass{
          .van-collapse-item__content:nth-child(1){
            padding-top: 0;
            padding-bottom: 0;

            div{
              padding: 6px 0px;

              span{
                display: inline-block;
              }

              .deletedBtn{
                float: right;
                border: none;
                background-color: red;
                color: white;
                border-radius: 5px;
              }

              .editBtn{
                float: right;
                border: none;
                background-color: dodgerblue;
                color: white;
                border-radius: 5px;
              }

              .addText{
                display: inline-block;
                width: 5%;
              }

              .addBtn{
                float: left;
                border: none;
                margin-left: 7px;
              }
            }
          }
        }

        .AgentClass{

          .van-collapse-item__content{
            padding-top: 0;
            padding-bottom: 0;

            .AgentItemClass{
              .AgentChildrenClass{
                div:nth-child(1){
                  padding-right: 0;
                }

                .van-collapse-item__wrapper{
                  margin-bottom: 5px;
                }

                .AgentChildrenMes{
                  display: flex;
                  flex-wrap: wrap;
                  margin-bottom: 3px;

                  div:nth-child(1){
                    width: 45%;

                    .completedSpan{
                      width: 100%;
                      margin: 0;
                      padding: 0;
                      display: inline-block;
                      color: #14bd14;
                    }

                    .noCompletedSpan{
                      width: 100%;
                      margin: 0;
                      padding: 0;
                      display: inline-block;
                    }
                  }

                  div:nth-child(2){
                    width: 55%;
                    display: flex;
                    flex-direction: row-reverse;
                    .deletedBtn{
                      float: right;
                      border: none;
                      background-color: red;
                      color: white;
                      border-radius: 5px;
                    }

                    .editBtn{
                      float: right;
                      border: none;
                      background-color: dodgerblue;
                      color: white;
                      border-radius: 5px;
                    }

                    .completedIconSuccess{
                      display: flex;
                      border-radius: 50%;
                      border: solid;
                      border-width: 1.5px;
                      background-color: white;
                      color: #14bd14;
                      border-color: #14bd14;
                    }

                    .completedIconCross{
                      display: flex;
                      border-radius: 50%;
                      border: solid;
                      border-width: 1.5px;
                      background-color: white;
                      color: #f52929;
                      border-color: #f52929;
                    }

                    .noCompletedIcon{
                      display: flex;
                      border-radius: 50%;
                      border: solid;
                      border-width: 1.5px;
                      background-color: white;
                      color: grey;
                      border-color: grey;
                    }
                  }
                }

                .addText{
                  display: inline-block;
                  width: 5%;
                }

                .addBtn{
                  float: left;
                  border: none;
                  margin: 0;
                }
              }


              .addText{
                display: inline-block;
                width: 5%;
              }

              .addBtn{
                float: left;
                border: none;
                margin: 0;
              }
            }
          }
        }
      }

      .noteBox{

        .timeBox{
          font-size: 0.25rem;
          padding: 0px 25px;
          padding-top: 10px;
          color: grey;
          display: flex;

          span:nth-child(1){
            width: 40%;
            margin: 0;
            padding: 0;
            display: inline-block;
            float: left;
          }

          span:nth-child(2){
            width: 40%;
            margin: 0;
            padding: 0;
            display: inline-block;
            float: right;
          }
        }

        .titleBoxClass{
          padding-bottom: 0px;

          div:nth-child(1){
            border: solid;
            border-width: 1px;
            border-color: grey;
            border-radius: 8px;

            .van-field__body{
              border: none;
              padding: 0px 8px;
            }
          }
        }

        .noteBoxClass{
          div:nth-child(1){
            border: solid;
            border-width: 1px;
            border-color: grey;
            border-radius: 8px;
            padding: 0px 4px;

            div:nth-child(1){
              border: none;
            }
          }
        }
      }

      .AgentManage{
        margin: 10px;
        display: flex;
        flex-direction: column;

        .agentItemClass{
          margin-bottom: 5px;
          height: 30px;
          display: flex;

          div:nth-child(1){
            width: 50%;

            span{
              width: 100%;
              margin: auto;
            }

            input{
              width: 100%;
              border-radius: 7px;
              padding: 0;
              height: 80%;
            }
          }

          div:nth-child(2){
            width: 50%;
            display: flex;
            flex-direction: row-reverse;

            .deletedBtn{
              float: right;
              border: none;
              background-color: red;
              color: white;
              border-radius: 5px;
            }

            .editBtn{
              float: right;
              border: none;
              background-color: dodgerblue;
              color: white;
              border-radius: 5px;
            }

            .cancelBtn{
              float: right;
              border: none;
              background-color: #ff7300;
              color: white;
              border-radius: 5px;
            }

            .saveBtn{
              float: right;
              border: none;
              background-color: green;
              color: white;
              border-radius: 5px;
            }

          }
        }

        .addAgentClass{
          .addText{
            display: inline-block;
            width: 5%;
          }

          .addBtn{
            float: left;
            border: none;
            margin: 0;
          }
        }
      }

    }
  }
}
</style>